import React from "react";
import styles from "./progressBar.module.scss";
import { useTranslation } from "react-i18next";
interface Props {
  dataRange?: string;
  value?: any;
  maxValue?: any;
  isInActive?: boolean;
  privilageCard?: string;
}
export const ProgressBar = (props: Props) => {
  const widthPercentage = (props.value / props.maxValue) * 100;
  const { t } = useTranslation();
  return (
    <div
      className={
        props.isInActive
          ? styles.progressBarContainerDiabled
          : styles.progressBarContainer
      }
    >
      <div className={styles.criteriaDate}>{props.dataRange}</div>
      <div
        className={
          props.isInActive ? styles.progressBarDiabled : styles.progressBar
        }
      >
        <div
          className={
            props.privilageCard == "PLATINUM"
              ? props.isInActive
                ? styles.progressFillPlatinumDiabled
                : styles.progressFillPlatinum
              : props.privilageCard == "SILVER"
              ? props.isInActive
                ? styles.progressFillSilverDiabled
                : styles.progressFillSilver
              : props.privilageCard == "GOLD"
              ? props.isInActive
                ? styles.progressFillGoldDiabled
                : styles.progressFillGold
              : ""
          }
          style={{ width: `${widthPercentage}%` }}
        />
      </div>
      <div className={styles.contentConatiner}>
        <div className={styles.content}>
          {Number(props.value)?.toLocaleString()} /{" "}
          {Number(props.maxValue).toLocaleString()}
        </div>
        {props.isInActive && (
          <div className={styles.content2}>{t("label_inactive")}</div>
        )}
      </div>
    </div>
  );
};
