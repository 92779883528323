import styles from "./criteria.module.scss";
import React, { useEffect, useState } from "react";

interface Props {
  title?: string;
  subTitle?: string;
  dataRange?: string;
}

export const Criteria = (props: Props) => {
  return (
    <>
      <div className={styles.criteriaHeader}>{props.title}</div>
      <div className={styles.criteriaSubHeader}>{props.subTitle}</div>
      <div className={styles.criteriaDate}>{props.dataRange}</div>
    </>
  );
};
