import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./cardcateogry.module.scss";
import { config } from "../../../../../config/global";
import { useDispatch, useSelector } from "react-redux";
import {
  getMemberStatusTermsFailure,
  getMemberStatusTermsSuccess,
} from "../../../slice/fetchMemberStatusCalculatorSlice";
import image from "../../../../../../public/assets/memberStatusCalsImage.svg";
import TGIcon from "../../../../../shared/tg-icon";

const CardCategory = () => {
  const TERMS_URL = config.TEAMSITE_MEMBER_STATUS_TERMS;
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const handleSize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleSize);

    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: TERMS_URL,
      isTeamsite: true,
      successAction: getMemberStatusTermsSuccess,
      errorAction: getMemberStatusTermsFailure,
    });
  }, [dispatch]);

  const { memberStatusTermsData } = useSelector(
    (state: any) => state?.osciReducer?.memberStatusCalculator
  );

  const settings = {
    slidesToShow: 1.18,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    beforeChange: (oldIndex: number, nexIndex: number) =>
      setCurrentSlide(Math.round(nexIndex)),
    customPaging: (i: number) => (
      <div>
        {i === currentSlide ? (
          <svg
            width="20"
            height="6"
            viewBox="0 0 20 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="6" rx="3" fill="#684B9B" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="6"
            viewBox="0 0 6 6"
            fill="none"
          >
            <circle opacity="0.5" cx="3" cy="3" r="3" fill="#CACAD9" />
          </svg>
        )}
      </div>
    ),
    appendDots: (dots: React.ReactNode) => <ul>{dots}</ul>,
  };
  return (
    <div className={`${styles.cardCategoryMain} global-content-padding`}>
      <div>
        <div className={styles.cardCategoryMainHead}>
          {memberStatusTermsData?.mainHeader}
        </div>
        <div className={styles.cardCategoryMainSubHead}>
          {memberStatusTermsData?.subHeading}
        </div>
      </div>
      {isMobile ? (
        <Slider {...settings} className={styles.memberCardMainSliderContainer}>
          {memberStatusTermsData?.memberContents?.map(
            (item: any, index: number) => (
              <div className={styles.memberCardMainContainerMobileMain}>
                <div className={styles.memberCardMainContainerMobile}>
                  <div
                    className={`${styles.memberCardMainContainerHeadingBg} ${
                      index === 0
                        ? styles.memberCardMainContainerIndexZero
                        : index === 1
                        ? styles.memberCardMainContainerIndexOne
                        : styles.memberCardMainContainerIndexTwo
                    }`}
                  >
                    <div
                      className={`${styles.memberCardMainContainerTitleCover} ${
                        index === 0
                          ? styles.memberCardMainContainerTitleIndexZero
                          : styles.memberCardMainContainerTitleIndexOne
                      }`}
                    >
                      {item?.heading}
                    </div>
                    <img
                      src={image}
                      alt="image"
                      className={styles.memberCardMainContainerHeadingImg}
                    />
                  </div>
                  <div className={styles.memberCardMainContainerContentMain}>
                    {item?.lists?.map((list: any, listIndex: number) => (
                      <div
                        key={listIndex}
                        className={
                          styles.memberCardMainContainerContentMainSingle
                        }
                      >
                        <div>
                          <TGIcon icon="member-status-check" fillColor="" />
                        </div>
                        <div
                          className={
                            styles.memberCardMainContainerContentMainSingleText
                          }
                        >
                          {list}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )
          )}
        </Slider>
      ) : (
        <div className={styles.memberCardMainContainerHead}>
          {memberStatusTermsData?.memberContents?.map(
            (item: any, index: number) => (
              <div key={index} className={styles.memberCardMainContainer}>
                <div
                  className={`${styles.memberCardMainContainerHeadingBg} ${
                    index === 0
                      ? styles.memberCardMainContainerIndexZero
                      : index === 1
                      ? styles.memberCardMainContainerIndexOne
                      : styles.memberCardMainContainerIndexTwo
                  }`}
                >
                  <div
                    className={`${styles.memberCardMainContainerTitleCover} ${
                      index === 0
                        ? styles.memberCardMainContainerTitleIndexZero
                        : styles.memberCardMainContainerTitleIndexOne
                    }`}
                  >
                    {item?.heading}
                  </div>
                  <img
                    src={image}
                    alt="image"
                    className={styles.memberCardMainContainerHeadingImg}
                  />
                </div>
                <div className={styles.memberCardMainContainerContentMain}>
                  {item?.lists?.map((list: any, listIndex: number) => (
                    <div
                      key={listIndex}
                      className={
                        styles.memberCardMainContainerContentMainSingle
                      }
                    >
                      <div>
                        <TGIcon icon="member-status-check" fillColor="" />
                      </div>
                      <div
                        className={
                          styles.memberCardMainContainerContentMainSingleText
                        }
                      >
                        {list}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default CardCategory;
