import React, { useEffect, useState } from "react";
import { Banner } from "./banner";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileRequest } from "../../slice/fetchProfileSlice";
import { getMemberTierCalclulatorPending } from "../../slice/fetchMemberStatusCalculatorSlice";
import { RootState } from "../../slice/RootReducer";
import CardCategory from "./cardCategory";
import { StatusCard } from "./statusCard";
import moment from "moment";
interface Props {
  isloadingProfileData?: boolean;
  vaildityDate?: any;
  privilageCard?: string;
  memberStatusTierData?:any;
}

export const MemberStatusCalculator = (props: Props) => {
  const dispatch = useDispatch();
  const currentDate = moment().format("YYYY-MM-DD");
  useEffect(() => {
    dispatch(fetchProfileRequest());
    dispatch(
      getMemberTierCalclulatorPending({ upgradeSimulationDate: currentDate })
    );
  }, [dispatch]);
 const memberStatusTierDatas = useSelector(
    (state: RootState) => state?.osciReducer?.memberStatusCalculator
  );
  console.log( memberStatusTierDatas ,"mmm")
  const [isloadingProfileData, setIsloadingProfileData] =
    useState<boolean>(false);
  const [privilageCard, setPrivilageCard] = useState("");
  const [vaildityDate, setVaildityDate] = useState<any>(null);

  const profile = useSelector(
    (state: RootState) => state?.osciReducer?.profile
  );

  useEffect(() => {
    setPrivilageCard(profile?.profileData?.privilegeCard);
    setVaildityDate(profile.profileData.cardExpiryDate);
    setIsloadingProfileData(profile.isLoading);
  }, [profile]);

  return (
    <>
      <Banner
        privilageCard={privilageCard}
        isloadingProfileData={isloadingProfileData}
        vaildityDate={vaildityDate}
      />
      {privilageCard == "MEMBER" && <CardCategory />}
      {privilageCard != "MEMBER" && !memberStatusTierDatas?.isLoading &&(
        <StatusCard privilageCard={privilageCard}  memberStatusTierData ={memberStatusTierDatas} />
      )}
    </>
  );
};
