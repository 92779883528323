import styles from "./statusCard.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { config } from "../../../../../config/global";
import {
  getMemberStatusTermsFailure,
  getMemberStatusTermsSuccess,
} from "../../../slice/fetchMemberStatusCalculatorSlice";
import { Criteria } from "../criteria";
import { ProgressBar } from "../progressBar/index";
import TGIcon from "../../../../../shared/tg-icon";
import { RootState } from "../../../slice/RootReducer";
import { constant } from "../../../../../config/constant";
import moment from "moment";

interface Props {
  privilageCard?: string;
  value?: any;
  maxValue?: any;
  isInActive?: boolean;
  memberStatusTierData?: any;
}

export const StatusCard = (props: Props) => {
  const { t, i18n } = useTranslation();
  const TERMS_URL = config.TEAMSITE_MEMBER_STATUS_TERMS;
  const [criteria1Array, setCriteria1Array] = useState<any>([]);
  const [criteria2Array, setCriteria2Array] = useState<any>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: TERMS_URL,
      isTeamsite: true,
      successAction: getMemberStatusTermsSuccess,
      errorAction: getMemberStatusTermsFailure,
    });
  }, [dispatch]);
  const privilageCard = props.privilageCard;

  const { memberStatusTermsData } = useSelector(
    (state: any) => state?.osciReducer?.memberStatusCalculator
  );
  useEffect(() => {
    let criteriaList =
      props.memberStatusTierData?.memberTierData?.Retain?.FlatListData;
    if (criteriaList) {
      criteriaList.forEach((element) => {
        if (element.Criteria == "Criteria 1") {
          setCriteria1Array(element.Dates);
        }
        if (element.Criteria == "Criteria 2") {
          setCriteria2Array(element.Dates);
        }
      });
    }
   
  }, [props.memberStatusTierData]);
 
  const isTodayInRange = (srtDate, endDat) => {
    const startDate = moment(srtDate);
    const endDate = moment(endDat);

    const today = moment();

    return today.isBetween(startDate, endDate, null, "[]");
  };
  return (
    <div className={`${styles.cardCategoryMain} global-content-padding`}>
      <>
        <div className={styles.cardCategoryMainHead}>
          {privilageCard == "SILVER"
            ? `${t("retain")} ${constant.review_info_silver} ${t("status")}`
            : privilageCard == "GOLD"
            ? `${t("retain")} ${constant.review_info_gold} ${t("status")}`
            : privilageCard == "PLATINUM"
            ? `${t("retain")} ${constant.review_info_platinum} ${t("status")}`
            : ""}
        </div>
        <div className={styles.cardCategorySubHeadwrapper}>
          <div className={styles.cardCategoryMainSubHead}>
            {privilageCard == "SILVER"
              ? `${t("earn")} ${
                  memberStatusTermsData?.thresholdBonusMiles?.silver
                } ${t("labe_bonus_miles")}`
              : privilageCard == "GOLD"
              ? `${t("earn")} ${
                  memberStatusTermsData?.thresholdBonusMiles?.gold
                } ${t("labe_bonus_miles")}`
              : privilageCard == "PLATINUM"
              ? `${t("earn")} ${
                  memberStatusTermsData?.thresholdBonusMiles?.platinum
                } ${t("labe_bonus_miles")}`
              : ""}
          </div>
          <div className={styles.subtext}>
            {t("learn_more")}
            <span>
              <TGIcon
                icon={"arrow-right"}
                size="24px"
                fillColor="#684B9B"
              ></TGIcon>
            </span>
          </div>
        </div>
      </>
      <div className={styles.cardBody}>
        <div className={styles.cardContent}>
          <Criteria
            title={t("label_criteria_1")}
            subTitle={`${criteria1Array[0]?.RequiredPoints} ${t(
              "label_qualifying_miles_12"
            )}`}
          />
          <div className={privilageCard == "PLATINUM" ? styles.progressBarsContainerPlatinum : styles.progressBarsContainer}>
            <ProgressBar
              value={criteria1Array[0]?.TotalPoints}
              maxValue={criteria1Array[0]?.RequiredPoints}
              dataRange={privilageCard == "PLATINUM" ? `${moment(criteria1Array[0]?.BetweenStartDate).format(
                "DD MMM YYYY"
              )} - ${moment(criteria1Array[0]?.BetweenEndDate).format(
                "DD MMM YYYY"
              )} (${t("period_1")})`: `${moment(criteria1Array[0]?.BetweenStartDate).format(
                "DD MMM YYYY"
              )} - ${moment(criteria1Array[0]?.BetweenEndDate).format(
                "DD MMM YYYY"
              )}`
            }
              isInActive={!isTodayInRange(criteria1Array[0]?.BetweenStartDate, criteria1Array[0]?.BetweenEndDate)}
              privilageCard={privilageCard}
            />
            { privilageCard == "PLATINUM"  && <div className={styles.cardDivider}>
          <hr className={styles.hrLine} />
          and
          <hr className={styles.hrLine} />
        </div>}
           <ProgressBar
              value={criteria1Array[1]?.TotalPoints}
              maxValue={criteria1Array[1]?.RequiredPoints}
              dataRange={privilageCard == "PLATINUM" ? `${moment(criteria1Array[1]?.BetweenStartDate).format(
                "DD MMM YYYY"
              )} - ${moment(criteria1Array[1]?.BetweenEndDate).format(
                "DD MMM YYYY"
              )} (${t("period_2")})`: `${moment(criteria1Array[1]?.BetweenStartDate).format(
                "DD MMM YYYY"
              )} - ${moment(criteria1Array[1]?.BetweenEndDate).format(
                "DD MMM YYYY"
              )}`}
              isInActive={!isTodayInRange(criteria1Array[1]?.BetweenStartDate, criteria1Array[1]?.BetweenEndDate)}
              privilageCard={privilageCard}
            />
          </div>
        </div>
        { privilageCard != "PLATINUM"  && <div className={styles.cardDivider}>
          <hr className={styles.hrLine} />
          Or
          <hr className={styles.hrLine} />
        </div>}
        { privilageCard != "PLATINUM"  && <div className={styles.cardContent}>
          <Criteria
            title={t("label_criteria_2")}
            subTitle={`${criteria2Array[0]?.RequiredPoints} ${t(
              "label_qualifying_miles_24"
            )}`}
          />
          <ProgressBar
            value={criteria2Array[0]?.TotalPoints}
            maxValue={criteria2Array[0]?.RequiredPoints}
            dataRange={`${moment(criteria2Array[0]?.BetweenStartDate).format(
              "DD MMM YYYY"
            )} - ${moment(criteria2Array[0]?.BetweenEndDate).format(
              "DD MMM YYYY"
            )} `}
            isInActive={!isTodayInRange(criteria2Array[0]?.BetweenStartDate, criteria2Array[0]?.BetweenEndDate)}
            privilageCard={privilageCard}
          />
        </div>}
      </div>
    </div>
  );
};
