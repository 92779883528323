import React from "react";
import styles from "./banner.module.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getCardImg, camelCase } from "../../../../../utils/helper";
import TGIcon from "../../../../../shared/tg-icon";
import { constant } from "../../../../../config/constant";

interface Props {
  isloadingProfileData?: boolean;
  vaildityDate?: any;
  privilageCard?: string;
}

export const Banner = (props: Props) => {
  const { t, i18n } = useTranslation();
  const {
    review_info_silver,
    review_info_gold,
    review_info_platinum,
    review_info_member,
  } = constant;

  const privilageCard = props.privilageCard;

  const vaildityDate = props.vaildityDate;
  const isloadingProfileData = props.isloadingProfileData;

  const getMemberTierImg = () => {
    const currentTier = privilageCard?.toLowerCase();
    return `../${getCardImg(currentTier)}`;
  };

  return (
    <>
      {!isloadingProfileData && privilageCard && (
        <div
          className={
            privilageCard == "PLATINUM"
              ? styles.mainContainerPlatinum
              : privilageCard == "SILVER"
              ? styles.mainContainerSilver
              : privilageCard == "GOLD"
              ? styles.mainContainerGold
              : privilageCard == "MEMBER"
              ? styles.mainContainerMember
              : ""
          }
        >
          <div className={`${styles.subContainer1}
              global-content-padding`}>
            <div className={styles.heading1}>{t("label_current_status")}</div>
            <div className={styles.heading2}>
              {privilageCard == "PLATINUM"
                ? review_info_platinum
                : privilageCard == "SILVER"
                ? review_info_silver
                : privilageCard == "GOLD"
                ? review_info_gold
                : privilageCard == "MEMBER"
                ? review_info_member
                : ""}
            </div>
            <div className={styles.heading3}>
              {privilageCard == "MEMBER"
                ? `${t("label_consent_success_member")} ${moment(
                    vaildityDate
                  ).format("DD MMM YYYY")}`
                : `${t("label_valid_thru")} ${moment(vaildityDate).format(
                    "DD MMM YYYY"
                  )}`}
            </div>
          </div>
          {privilageCard != "PLATINUM" && (
            <div className={`${styles.subContainer2} 
              global-content-padding`}>
              <div className="d-flex align-items-center gap-2">
                {getMemberTierImg() && (
                  <img
                    src={getMemberTierImg()}
                    height={"40px"}
                    width={"80px"}
                    alt="tier"
                  />
                )}
              </div>
              <div className={styles.contentConatiner}>
              <div className={styles.textBox}>
              {privilageCard == "SILVER"
                ? t("fly_more_gold")
                : privilageCard == "GOLD"
                ? t("fly_more_platinum")
                : privilageCard == "MEMBER"
                ?t ("fly_more_silver")
                : ""}
              </div>

              <div className={styles.buttonBox}>
                {t("miles_calculator")}
                <span>
                  <TGIcon
                    icon={"arrow-right"}
                    size="24px"
                    fillColor="white"
                  ></TGIcon>
                </span>
              </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
