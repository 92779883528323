// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
._2M4CokdpluN5Np5O5GsO {
  color: var(--gray-text-strong, #2C3059);
  /* Desktop/H5 — Bold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 27px */
  width: 100%;
}

.PQNbwklje0GVKo6Nfz0d {
  color: var(--gray-text, #6A6D87);
  /* Body/Body — Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

.mCF52_gBCxb_jFDb6ofj {
  color: var(--gray-text, #6A6D87);
  margin-top: 24px;
  /* Body/Body — Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}`, "",{"version":3,"sources":["webpack://./src/OSCI/src/pages/member-status-calculator/criteria/criteria.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,uCAAA;EAEA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,WAAA;AACJ;;AAEA;EACI,gCAAA;EAGA,wBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AADJ;;AAGA;EACI,gCAAA;EACJ,gBAAA;EACI,wBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAAJ","sourcesContent":[".criteriaHeader {\n    color: var(--gray-text-strong, #2C3059);\n\n    /* Desktop/H5 — Bold */\n    font-family: Inter;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 150%;\n    /* 27px */\n    width: 100%;\n}\n\n.criteriaSubHeader {\n    color: var(--gray-text, #6A6D87);\n\n\n    /* Body/Body — Regular */\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 160%;\n    /* 25.6px */\n}\n.criteriaDate{\n    color: var(--gray-text, #6A6D87);\nmargin-top: 24px;\n    /* Body/Body — Regular */\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 160%; /* 25.6px */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"criteriaHeader": `_2M4CokdpluN5Np5O5GsO`,
	"criteriaSubHeader": `PQNbwklje0GVKo6Nfz0d`,
	"criteriaDate": `mCF52_gBCxb_jFDb6ofj`
};
export default ___CSS_LOADER_EXPORT___;
